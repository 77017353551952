<template>
  <div class="modal-accept">
    <div class="modal-accept__body">
      <div class="modal-accept__title">{{ title }}</div>
      <div
        v-if="text"
        class="modal-accept__text"
      >{{ text }}</div>
    </div>
    <div class="modal-accept__footer">

      <router-link
        v-if="to"
        :to="to"
        @click.native="dialogConfirm"
        class="modal-accept__btn"
      >Перейти</router-link>

      <div
        v-else
        class="modal-accept__btn"
        @click="dialogConfirm"
      >Ок</div>

    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
    text: String,
    to: Object,
    resolve: {
      type: Function,
      default: () => false
    },
  },
  methods: {
    dialogConfirm() {
      this.$emit('confirm');
      this.$emit('close');
      this.resolve(true);
    },
  }
};
</script>

<style lang="scss" scoped>

// modal-accept
.modal-accept {
  width: 100%;
  max-width: 300px;
  .modal-accept__body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 120px;
    padding: 25px 24px;
    .modal-accept__title {
      color: #000000;
      font-weight: 600;
      font-size: 17px;
      text-align: center;
    }
    .modal-accept__text {
      margin-top: 10px;
      font-size: 17px;
      text-align: center;
    }
  }
  .modal-accept__footer {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(118, 118, 128, 0.12);
  }
  .modal-accept__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 11px 10px;
    color: var(--color-prime);
    font-weight: 600;
    font-size: 17px;
    transition: all .2s ease-in-out;
    cursor: pointer;
    &:hover {
      color: var(--color-prime-active);
    }
  }
}

</style>
